<template>
	<div>
		<CRow>
			<CCol md="12">
				<CCard>
					<CCardHeader>
						<CRow>
							<CCol md="8">
								<h4>Edit Status Permohonan</h4>
							</CCol>
						</CRow>
					</CCardHeader>
					<CForm v-on:submit.prevent="storeProcess" class="fwdwd">
						<CCardBody>
							<div class="form-group">
								<label for=""><b>Kategori<span class="text-danger">*</span></b></label>
								<v-select :options="list_kategori" label="label" v-model="st_kategori"></v-select>
							</div>
							<div class="form-group">
								<label for=""><b>Nama Status<span class="text-danger">*</span></b></label>
								<input type="text" class="form-control" placeholder="Nama Status" v-model="st_nama">
							</div>
							<div class="form-group">
								<label for=""><b>Role Verifikator<span class="text-danger">*</span></b></label>
								<v-select :options="list_role" label="name" v-model="role_selected"></v-select>
							</div>
							<div class="form-group">
								<label for=""><b>Urutan<span class="text-danger">*</span></b></label>
								<input type="text" class="form-control" placeholder="Urutan Status" v-model="st_urutan">
							</div>
							<div class="form-group">
								<label for=""><b>Kategori Status<span class="text-danger">*</span></b></label>
								<v-select :options="list_kategori_status" label="label" v-model="st_kategori_status"></v-select>
							</div>
							<div class="form-group form-check">
								<input type="checkbox" class="form-check-input" v-model="is_verifikasi_dokumen">
								<label class="form-check-label" for="is_verifikasi_dokumen">is_verifikasi_dokumen</label>
							</div>
							
							<div class="form-group form-check">
								<input type="checkbox" class="form-check-input" v-model="is_verifikasi">
								<label class="form-check-label" for="is_verifikasi">is_verifikasi</label>
							</div>
							
							<div class="form-group form-check">
								<input type="checkbox" class="form-check-input" v-model="is_reject">
								<label class="form-check-label" for="is_reject">is_reject</label>
							</div>
							
							<div class="form-group form-check">
								<input type="checkbox" class="form-check-input" v-model="is_reject_void">
								<label class="form-check-label" for="is_reject_void">is_reject_void</label>
							</div>
							
							<div class="form-group form-check">
								<input type="checkbox" class="form-check-input" v-model="is_return">
								<label class="form-check-label" for="is_return">is_return</label>
							</div>
							
							<div class="form-group form-check">
								<input type="checkbox" class="form-check-input" v-model="st_unker">
								<label class="form-check-label" for="st_unker">st_unker</label>
							</div>
							
							<div class="form-group form-check">
								<input type="checkbox" class="form-check-input" v-model="is_pemasukan">
								<label class="form-check-label" for="is_pemasukan">is_pemasukan</label>
							</div>
							
							<div class="form-group form-check">
								<input type="checkbox" class="form-check-input" v-model="is_edit_benih">
								<label class="form-check-label" for="is_edit_benih">is_edit_benih</label>
							</div>
							
							<div class="form-group form-check">
								<input type="checkbox" class="form-check-input" v-model="is_proses_by_komoditas">
								<label class="form-check-label" for="is_proses_by_komoditas">is_proses_by_komoditas</label>
							</div>
							
							<div class="form-group form-check">
								<input type="checkbox" class="form-check-input" v-model="is_persetujuan_benih">
								<label class="form-check-label" for="is_persetujuan_benih">is_persetujuan_benih</label>
							</div>
							
							<div class="form-group form-check">
								<input type="checkbox" class="form-check-input" v-model="is_proses_mentri">
								<label class="form-check-label" for="is_proses_mentri">is_proses_mentri</label>
							</div>
							<div class="form-group form-check">
								<input type="checkbox" class="form-check-input" v-model="is_generate_sk">
								<label class="form-check-label" for="is_generate_sk">is_generate_sk</label>
							</div>
							
							<div class="form-group form-check">
								<input type="checkbox" class="form-check-input" v-model="is_proses_dirjen">
								<label class="form-check-label" for="is_proses_dirjen">is_proses_dirjen</label>
							</div>
							
							<div class="form-group form-check">
								<input type="checkbox" class="form-check-input" v-model="is_notif_pemohon">
								<label class="form-check-label" for="is_notif_pemohon">is_notif_pemohon</label>
							</div>
							

						</CCardBody>
						<CCardFooter>
							<div class="text-right">
								<button type="submit" class="btn btn-primary text-white btn-hover-outline-primary"><i class="fa fa-save"></i> Simpan</button>
							</div>
						</CCardFooter>
					</CForm>
				</CCard>
			</CCol>
		</CRow>
	</div>
</template>
<script>
	export default {
		name: "EditStatusPermohonan",
		data() {
			return {
				idStatus: this.$route.params.idStatus,
				st_kategori: '',
				st_nama: '',
				roles_id: '',
				st_urutan: '',
				role_selected: [],
				st_kategori_status: '',
				list_role: [],
				list_kategori: [
                    {
                        value: "Hortikultura",
                        label: "Hortikultura",
                    },
                    {
                        value: "Sumber Daya Genetik",
                        label: "Sumber Daya Genetik",
                    },
                    {
                        value: "Tanaman Pangan",
                        label: "Tanaman Pangan",
                    },
                    {
                        value: "Perkebunan",
                        label: "Perkebunan",
                    },
                ],
				list_kategori_status: [
					{
                        value: "draft",
                        label: "draft",
                    },
					
					{
                        value: "ajukan",
                        label: "Pengajuan Permohonan",
                    },
					
					{
                        value: "proses",
                        label: "Proses Verifikasi",
                    },
					
					{
                        value: "dibatalkan",
                        label: "Batal",
                    },
					
					{
                        value: "menunggu perbaikan",
                        label: "Menunggu Perbaikan",
                    },
					
					{
                        value: "selesai",
                        label: "Selesai",
                    },
					

				],
				is_verifikasi_dokumen: false,
				is_verifikasi: false,
				is_reject: false,
				is_reject_void: false,
				is_return: false,
				st_unker: false,
				is_pemasukan: false,
				is_edit_benih: false,
				is_proses_by_komoditas: false,
				is_persetujuan_benih: false,
				is_proses_mentri: false,
				
				is_generate_sk: false,
				is_proses_dirjen: false,
				is_notif_pemohon: false,
			};
		},
		methods: {
			storeProcess: function () {
				let self = this;
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Loading...",
					showConfirmButton: false,
					allowOutsideClick: false,
				});
				axios.put(
                    this.apiLink + "api/crud/status_permohonan",
                    {
                        id: this.idStatus,
                        st_kategori: this.st_kategori?.value,
						st_nama: this.st_nama,
						st_urutan: this.st_urutan,
						roles_id: this.role_selected?.id,
						st_kategori_status: this.st_kategori_status?.value,
						is_verifikasi_dokumen: this.is_verifikasi_dokumen,
						is_verifikasi: this.is_verifikasi,
						is_reject: this.is_reject,
						is_reject_void: this.is_reject_void,
						is_return: this.is_return,
						st_unker: this.st_unker,
						is_pemasukan: this.is_pemasukan,
						is_edit_benih: this.is_edit_benih,
						is_proses_by_komoditas: this.is_proses_by_komoditas,
						is_persetujuan_benih: this.is_persetujuan_benih,
						is_proses_mentri: this.is_proses_mentri,
						
						
						is_generate_sk: this.is_generate_sk,
						is_proses_dirjen: this.is_proses_dirjen,
						is_notif_pemohon: this.is_notif_pemohon,
                        
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + this.access_token,
                        },
                    }
                )
				.then(function (response) {
					var res_data = response.data;
					// console.log(res_data);
					if (res_data.meta.code != "200") {
						Swal.fire({
							icon: 'error',
							title: 'Oops...',
							text: res_data.data.message
						});
					}
					else{
						let timerInterval;
						Swal.fire({
							icon: "success",
							title: "Berhasil",
							text: "Anda akan diarahkan ke halaman master status permohonan segera",
							timer: 2000,
							timerProgressBar: true,
							showCancelButton: false,
							showConfirmButton: false,
							didOpen: () => {
								timerInterval = setInterval(() => {
									const content = Swal.getContent();
									if (content) {
										const b = content.querySelector("b");
										if (b) {
											b.textContent = Swal.getTimerLeft();
										}
									}
								}, 100);
							},
							willClose: () => {
								clearInterval(timerInterval);
							},
						}).then((result) => {
							/* Read more about handling dismissals below */
							if (result.dismiss === Swal.DismissReason.timer) {
								self.$router.push({name: 'AllStatus Permohonan'});
							}
						});
					}
				});
			},
		},
		created() {
            Swal.fire({
                title: '<i class="fa fa-refresh fa-spin"></i>',
                text: "Loading...",
                showConfirmButton: false,
                allowOutsideClick: false,
            });
            axios.get(this.apiLink + "api/crud/status_permohonan", {
                params: {
                    id: this.idStatus,
                },
                headers: {
                    Authorization: "Bearer " + this.access_token,
                },
            })
            .then((response) => {
                var res_status_permohonan = response.data;
                var res_status_permohonan_data = res_status_permohonan.data[0].data[0];
                if (res_status_permohonan.meta.code != "200") {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: res_status_permohonan.data.message
                    });
                }
                else{
                    Swal.close();
                    this.st_kategori = this.list_kategori.find(as_st_kategori => as_st_kategori.value == res_status_permohonan_data.st_kategori);
					this.st_nama = res_status_permohonan_data.st_nama;
					this.role_selected = res_status_permohonan_data.roles;
					this.st_urutan = res_status_permohonan_data.st_urutan;
					this.st_kategori_status = this.list_kategori_status.find(as_st_kategori_status => as_st_kategori_status.value == res_status_permohonan_data.st_kategori_status);
					this.is_verifikasi_dokumen = res_status_permohonan_data.is_verifikasi_dokumen;
					this.is_verifikasi = res_status_permohonan_data.is_verifikasi;
					this.is_reject = res_status_permohonan_data.is_reject;
					this.is_reject_void = res_status_permohonan_data.is_reject_void;
					this.is_return = res_status_permohonan_data.is_return;
					this.st_unker = res_status_permohonan_data.st_unker;
					this.is_pemasukan = res_status_permohonan_data.is_pemasukan;
					this.is_edit_benih = res_status_permohonan_data.is_edit_benih;
					this.is_proses_by_komoditas = res_status_permohonan_data.is_proses_by_komoditas;
					this.is_persetujuan_benih = res_status_permohonan_data.is_persetujuan_benih;
					this.is_proses_mentri = res_status_permohonan_data.is_proses_mentri;
					
					
					this.is_generate_sk = res_status_permohonan_data.is_generate_sk;
					this.is_proses_dirjen = res_status_permohonan_data.is_proses_dirjen;
					this.is_notif_pemohon = res_status_permohonan_data.is_notif_pemohon;
					
                }
            });
        }
	};
</script>